<template>
  <div
    :class="{ 'nav-visible-skinny': navVisible }"
    class="w-full lg:w-10/12 mx-auto min-h-screen flex flex-col justify-center items-center pt-18"
  >
    <div id="timeline" style="width: 100%; height: 600px"></div>
    <button
      v-click-outside="{
        include: ['.tl-timenav', '.tl-menubar'],
        handler: () => (navVisible = false),
      }"
      @click="navVisible = !navVisible"
      :style="nav"
      class="absolute bg-white rounded-r-lg nav-button"
      :class="{ hidden: navVisible }"
    >
      <span
        class="w-6/12 mx-auto border border-black border-opacity-60 block mb-1"
      ></span
      ><span
        class="w-6/12 mx-auto border border-black border-opacity-60 block mb-1"
      ></span
      ><span
        class="w-6/12 mx-auto border border-black border-opacity-60 block mb-1"
      ></span>
    </button>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  data() {
    return {
      navVisible: false,
      nav: {
        top: '400px',
        left: '0',
        width: '42px',
        height: '96px',
        'z-index': 9999,
      },
    };
  },
  async mounted() {
    await nextTick();

    let script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://cdn.knightlab.com/libs/timeline3/latest/js/timeline.js',
    );

    script.addEventListener('load', (e) => {
      const timeline = new window.TL.Timeline(
        'timeline',
        'https://docs.google.com/spreadsheets/d/1CqVREsnx2N0g4W2Rf8tl0QL5oJQFHJPnXRAptbNOK0s/edit#gid=0',
        { font: 'regular' },
      );

      const topLine = document.querySelector('#timeline').offsetTop;
      this.nav['top'] = topLine + 453 + 'px';
    });

    document.head.appendChild(script);
  },
};
</script>

<style lang="scss">
.tl-slide-scrollable-container,
.tl-slide-content {
  width: 100% !important;
  flex-direction: column !important;
  color: black !important;
}

.tl-text p {
  color: black !important;
}

@media only screen and (min-width: 649px) {
  .nav-button {
    display: none;
  }
}
</style>
